import React from "react";
import BackgroundImage from "gatsby-background-image";

const BannerText = (props) => {

    let arrow_classes;

    if (props.arrowPosition && props.arrowPosition === 'top') {
        arrow_classes = 'top-0 -translate-y-full border-b-primary border-t-0 border-r-32 border-b-32 border-l-32 lg:border-r-54 lg:border-b-54 lg:border-l-54';
    } else if (props.arrowPosition && props.arrowPosition === 'bottom') {
        arrow_classes = 'bottom-0 translate-y-full border-t-primary border-b-0 border-r-32 border-l-32 border-t-32 lg:border-r-54 lg:border-l-54 lg:border-t-54';
    }

    return (
        <BackgroundImage tag="div"
                         className="relative bg-primary bg-center bg-cover"
                         fluid={props.background}>
            <div className="py-10 container">
                <div className="mx-auto max-w-2xl text-center text-white">
                    <h2 className="mb-3 text-2xl uppercase leading-none md:text-3xl lg:text-5xl" dangerouslySetInnerHTML={{ __html: props.title }}></h2>
                    <p className="md:text-xl lg:text-2xl" dangerouslySetInnerHTML={{ __html: props.summary }}></p>
                </div>
            </div>
            {props.arrowPosition && props.arrowPosition !== 'none' &&
                <span className={"absolute left-1/2 w-0 h-0 z-20 transform -translate-x-1/2 border-solid border-transparent " + arrow_classes}></span>
            }
        </BackgroundImage>
    )
};

export default BannerText;
